@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.css";

body {
  background-image: url("./images/main_background.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-layout {
  margin: 5% 10%;
}

.page-layout {
  display: block;
  padding: 5% 10%;
  margin-bottom: 5%;
}

.header-logo {
  height: 80px;
  overflow: hidden;
  margin-right: auto;

  .logo-img {
    height: 100%;
  }

  @include media-breakpoint-down(lg) {
    height: 40px;
  }

  @include media-breakpoint-down(sm) {
    width: 0;
    height: 0;
  }
}

.header-avatar {
  object-fit: cover;
  margin: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

nav a {
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.text-shadows {
  text-shadow: pink 1px 0 10px, black 2px 0 15px;
}

.data-table {
  tr.path:hover {
    background-color: $table-hover-bg;
  }

  @include media-breakpoint-down(lg) {
    display: block;

    thead {
      display: none;
    }

    tbody {
      display: flex;
      gap: $spacer * 1.2;
      flex-wrap: wrap;
      justify-content: space-around;

      tr {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0 $card-spacer-x;
        background-color: $card-bg;
        border: $card-border-width solid $card-border-color;
        border-radius: $card-border-radius;
        box-shadow: $box-shadow;

        td:last-child {
          border: none;
        }
      }
    }
  }
}

.form-switch.form-switch-md {
  .form-check-input {
    width: 2.8rem;
    height: 1.5rem;
  }
  .form-check-label {
    padding-top: 4px;
    padding-left: 8px;
  }
}
